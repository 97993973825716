import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/downloadApp',
    name: 'DownloadApp',
    component: () => import('../views/DownloadApp.vue')
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/choiceness',
    name: 'Choiceness',
    component: () => import('../views/Choiceness.vue')
  },
  {
    path:"/summary",
    name:"Summary",
    component: ()=> import('../views/Summary.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
