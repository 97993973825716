<template>
  <div class="main">
    <Nav />
    <div class="mainCont">
      <router-view />
    </div>
    <PageFooter :footerColor="footerColor" />
  </div>
</template>
<script>
import { ref } from "vue";
import Nav from "./components/TopNav";
import PageFooter from "./components/PageFooter";
export default {
  components: { Nav, PageFooter },
  setup() {
    const footerColor = ref("#fff");
    return {
      footerColor,
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.path === "/") {
        this.footerColor = "#F9FAFF";
      } else {
        this.footerColor = "#FFF";
      }
      $(".main").scrollTop(0);
    },
  },
  mounted() {
    document.dispatchEvent(new Event("custom-render-tigger"));
  },
};
</script>
<style lang="scss">
.mainCont {
  min-height: 82.5%;
}
.showBlock,.show{
  display: none !important;
}
@media screen and (max-width: 600px) {
  .hiden{
    display: none !important;
  }
  .show{
    display: inline-block !important;
  }
  .showBlock{
    display: block !important;
  }
  .mainCont{
    min-height: auto;
  }
}

</style>
